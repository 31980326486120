<template>
  <div :style="componentStyle">
    <h4 style="text-align: center">
      Unable to collect updates. Your connection to our server has been
      interrupted or may be too slow to pull more updates. Please verify that
      your connection is setup properly and refresh this window. If the problem
      persists please contact our support team.
    </h4>
  </div>
</template>

<script>
export default {
  name: 'DisconnectionWarning',
  data() {
    return {
      componentStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        padding: '10px',
        zIndex: 1000,
        background: 'rgba(255, 0, 27, 0.5)'
      }
    }
  }
}
</script>
